<template>
	<div class="box">
		<h3 v-if="horse_name">{{ horse_name }}</h3>

		<CustomTable
			v-if="!reloadingTransformer"
			:id_table="tableId"
			ref="table"
			:transformer.sync="Transformer"
			:base-filters="filters"
			:busy.sync="table_busy"
			primaryKey="actes_id"
			:display_action_button="!horseFicheReadOnly"
			:checkboxes="!horseFicheReadOnly"
			:date-filter="dateFilter"
			:persistDateFilter="true"
			:externSlotColumns="['notes.type_fonts', 'ordonnance']"
			:hrefsRoutes="config_table_hrefs"
			:dateRangeV2="true"
			:buttonActionEventOnly="button_action_event_only"
		>
			<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
				<div 
					v-if="data.notes && Object.keys(data.notes.type_fonts).length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"	
				>
					<span 
						v-for="(font, key) in data.notes.type_fonts"	
						:key="key"
					>
						<font-awesome-icon :icon="font.font" /> {{ font.occurences }}
					</span>
				</div>
			</template>
			<template v-slot:[`custom-slot-cell(ordonnance)`]="{ data }">
				<div 
					v-if="data.actes_document_lien.length > 0" 
				>
					<div v-for="document of data.actes_document_lien" :key="document.document_id">
						<a href="#" @click="viewDocumentOrdonnance(document)">
							{{ document.document_name }}
						</a>
					</div>
					
				</div>
				<div 
					v-else-if="data.actetype.actestype_needordonnance" 
				>
					<a href="#" @click="addOrdonnance([data])">
						<font-awesome-icon :icon="['far', 'file-medical-alt']"  />
					</a>
				</div>
			</template>
		</CustomTable>
		<ModalDemandeAnalyse
            ref="modal_demande_analyse"
        />

        <ModalAskPaillettes
            ref="modal_ask_paillettes"
            :callback_confirm="goToDeleteActes"
        />
		<b-modal :modal-class="'modal-pdf'" size="xl" ref="modalViewDocumentOrdonnance" hide-footer>
            <template slot="modal-header" slot-scope="{ close }">
                <h2 class="mx-auto my-auto">{{ $t('ordonnance.document_visualisation') }}</h2>
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
            </template>

			<OfflineMedia
				:filename="modal_document_filename"
				customClass="w-100"
			/>
        </b-modal>
		<b-modal size="xl" ref="ordonnanceModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			<OrdonnanceForm :horses_ids="horse_ids" :from_mediatheque="false" @documents="handleOrdoForm"/>
        </b-modal>

		<b-modal size="xl" ref="ordonnanceRenameModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			
			<OrdonnanceRename :horses_ids="horse_ids" :from_mediatheque="false" :documents.sync="documents" @success="HandleOrdoRename" :acte_ids="acte_ids"/>
        </b-modal>
		<ModalReplan 
            ref="modal_actes_replan"
            @ok="applyReplanActe"
        />
		<ModalCourrierActes ref="modal_courrier_actes"/>
	</div>
</template>


<script type="text/javascript">
	import ActesMixin from "@/mixins/Actes.js"
	import Navigation from "@/mixins/Navigation.js"
    import Shutter from '@/mixins/Shutter.js'
	import ShutterNotes from "@/mixins/shutters-manager/Notes.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'
    import StockSemence from '@/mixins/StockSemence.js'
	import ActeTypeMixin from "@/mixins/ActeType.js"
    import Common from '@/assets/js/common'
	import Constants from 'Constants'
	import { EventBus } from 'EventBus'
	import Documents from "@/mixins/Documents.js"


    export default {
        name: "ActesHorse",
        mixins: [
            ActesMixin,
            Navigation,
			Shutter,
			ShutterActs,
			ShutterNotes,
			StockSemence,
			ActeTypeMixin,
			Documents
        ],
        props: {
            horse_id: { type: Number, default: 0 },
			acte_statut: { type: Number, default: 0 },
			button_action_event_only: { type: Boolean, default: false},
	       	start_date: { type: Date, default: null },
			horse_name: { type: String, default: ''},
		},
        data () {
			return {
                /* Configuration du tableau : */
                table_busy: true,/* Définit si la table n'est pas encore chargée */
				reloadingTransformer: false,
                events_tab: {
                    'TableAction::DeleteActes': this.checkDeleteActes,  //this.applyDeleteActes,
                    'TableAction::goToAnalyse': this.openModalAnalyse,
					'TableAction::goToAddOrdonnanceFromAct': this.addOrdonnance,
                    'TableAction::goToDupliqueActe': this.dupliqueActe,
					'TableAction::ReplanActe': this.replanActesModal,
					'TableAction::goToPrintActs': this.openModalCourrierActes,
					'TableAction::acteAjout': this.handleActesOperations,
				},
                documents: [],
			    horse_ids: [],
			    acte_ids: [],
				modal_document_filename: null,

				/* Utilisé par la mixin */
				interacted_actes: [],

				reloadMethod: 'refreshTable',
				dateFilter: null,

				config_table_hrefs: {
					'entity_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'entity_id'
						}
					}
				},

                actes_to_delete: [],
                actes_iac: []
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
			async init_component() {
				this.setDateFilter()
			},

			refreshTable() {
				this.$refs.table.refreshTable()
			},

			replanActesModal(actes) {
                this.interacted_actes = actes
                const date = actes.length > 1 ? new Date() : Date.parseTz(actes[0].actes_date)
                this.$refs.modal_actes_replan.openModal(date, actes)
			},

			catchColumnNotesShutter(act) {
				this.handleNotePreview(act.notes.raw)
			},

            openModalAnalyse(actes) {
                this.$refs.modal_demande_analyse.openModal(actes)
            },

			setDateFilter() {
				let start = null
				let end = null
				
				if(this.start_date) {
					start = this.start_date
				}
				else if(this.getConfig('startDate' + this.tableId)) {
					start = new Date(this.getConfig('startDate' + this.tableId))
				}
				else {
					start = new Date(new Date().setMonth(new Date().getMonth() - 12))
				}

				if(this.start_date) {
					end = null
				}
				else if(this.getConfig('endDate' + this.tableId)) {
					end = new Date(this.getConfig('endDate' + this.tableId))
				}

				this.dateFilter = {
					column: 'actes_date',
					start,
					end
				}
			},

            async checkDeleteActes(actes) {
                this.actes_to_delete = actes
                if (!this.$store.state.userAccess.hasBreedingAccess) {
                    this.goToDeleteActes()
                    return false
                }

                const iac = await this.getActeTypeIAC()
                let has_iac = false
                this.actes_iac = []

                const collecte = await this.getActeTypeCollecte()
                let has_collecte = false

                await Common.asyncForEach(actes, async (acte) => {
                    if(acte.actes_actestype == iac.actestype_id && window.navigator.onLine) {
                        const paillettes = await this.loadStockUsedByActe(acte.actes_id)
                        if(paillettes.length > 0) {
                            has_iac = true
                            this.actes_iac.push(acte.actes_id)
                        }
                    }
                    else if (acte.actes_actestype == collecte.actestype_id && window.navigator.onLine) {
                        const embryons = await this.getCollecteEmbryons(acte.actes_id)
                        if(embryons.filter(embryon => embryon.embryon_transplantation).length > 0) {
                            has_collecte = true
                        }
                    }
                })
                if(has_collecte) {
                    this.failureToast("toast.collecte_embryon_transplantation")
                    return false
                }
                else if(has_iac) {
                    this.$refs.modal_ask_paillettes.openModal()
                }
                else {
                    this.goToDeleteActes()
                }
            },

            goToDeleteActes() {
                this.applyDeleteActes(this.actes_to_delete)

                if (this.$store.state.userAccess.hasBreedingAccess) {
                    this.actes_iac.forEach(acte => {
                        this.cancelActeUseSemence(acte)
                    })
                }
            },

            async dupliqueActe(acte_id) {
               const acte = await this.getActeFromId(acte_id, 'withContactAndArticles')
               this.setupActDuplication(acte)
            },

			addOrdonnance(actes) {
				this.horse_ids = actes.map(a => a.actes_horse)
				this.acte_ids = actes.map(a => a.actes_id)
				this.$refs.ordonnanceModal.show()
            },

            handleOrdoForm(documents) {
                this.$refs.ordonnanceModal.hide()
                this.documents = documents
                this.$refs.ordonnanceRenameModal.show()
            },

            HandleOrdoRename() {
                this.$refs.ordonnanceRenameModal.hide()
                this.$refs.table_perf.refreshTable()
            },
			async viewDocumentOrdonnance(data) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = data
					this.action_name = 'viewDocumentOrdonnance'
					return this.askPermissionAndroid()
				}

				const filename = data.document_filename

				if((Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING)
					&& filename.includes('.pdf')
				) {
					const blobFile = await this.$sync.loadOnDemandFile(filename)
					let reader = new FileReader()
					reader.readAsDataURL(blobFile)

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(() => {
								EventBus.$emit('TableAction::stopSpin')
							}, {
								filename: filename,
								base64: base64Cleanedup
							}, 'previewPdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, base64Cleanedup, 'previewPdfDocument')
						}
					}
				}
				else {
					this.$refs['modalViewDocumentOrdonnance'].show()
                	this.modal_document_filename = filename
					EventBus.$emit('TableAction::stopSpin')
				}
            },
			openModalCourrierActes(acte_ids) {
				this.$refs.modal_courrier_actes.openModal(acte_ids)
			},

            async handleActesOperations(params) {
                const operation = params.state || 'add'
                const row_id = params.pk_id || params.acte_id || params.horse_id

                if(operation == this.last_operation && row_id == this.last_row_id) {
                    this.last_operation = ''
                    this.last_row_id = 0

                    return
                }
                else if(operation == 'add') {
                    this.setupActAdd('', 'REPRO')
                }
                else if(operation == 'modification') {
                    this.setupActEdit(row_id, params.lot)
                }
                else if(operation == 'validation') {
                    this.setupActValidation(row_id)
                }

                this.last_operation = operation
                this.last_row_id = row_id
            },

            onShuttersSubmit() {
            	this.shutterPanel().close('act-add-form')
            	this.shutterPanel().close('act-edit')
            	this.shutterPanel().goTo('act-horse')
				this.$refs.table.unselectAll()
				this.$refs.table.refreshTable()
            }
        },
        computed: {
			tableId() {
				return parseInt(this.acte_statut) === 1 ? 'horse_actes' : 'horse_planning'
			},
			Transformer() {
				return parseInt(this.acte_statut) === 1 ? ['ActeTransformer', 'withHorseAndMainResidenceNotesWithoutBtTableProp'] : ['ActeTransformer', 'withHorseAndMainResidenceNotes']
			},
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            },
			filters() {
				return {
					is_horse: {
						column: 'actes_horse',
						operator: 'isEqualTo',
						value: this.$sync.replaceWithReplicated('horse', this.horse_id)
					},
					is_validated: {
						column: 'actes_actesstatut',
						operator: 'isEqualTo',
						value: parseInt(this.acte_statut)
					},
				}
			}
        },
		watch:{
			Transformer(){
				this.reloadingTransformer = true
				this.$nextTick(() => {
					this.reloadingTransformer = false
                });
			}
		},
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            ModalDemandeAnalyse: () => import('@/components/Modals/ModalDemandeAnalyse'),
            ModalAskPaillettes: () => import('@/components/Modals/ModalAskPaillettes'),
			ModalReplan: () => import('@/components/Modals/ModalReplan'),
			OrdonnanceForm: () => import("@/components/Documents/OrdonnanceForm"),
		    OrdonnanceRename: () => import("@/components/Documents/OrdonnanceRenameForm"),
			OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia'),
			ModalCourrierActes: () => import('@/components/Modals/ModalCourrierActes')
		}
	}
</script>
